
header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 13vh;
    padding: 0 2rem;
    background-color: white;
    color: #787878;
    border-radius: 0 0 30px 30px;
    box-shadow: 0px 2px 5px rgba($color: #dcdcdc, $alpha: 1.0);
}
.nav{
    display: flex;
    align-items: center;
}
.nav-logo{
    height: 12.5vh;
}

.nav-link{
    margin: 0 2rem;
    color: #787878;
    text-decoration: none;
    cursor: pointer;
    &:hover{
        color: black;
    }
}
.nav-btn{
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: #787878;
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
}


@media only screen and (max-width: 1024px) {
    .nav-btn{
        display: block;
        visibility: visible;
        opacity: 1;
    }
    .nav-logo{
        height: 10vh;
    }
    header nav{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: white;
        transition: 1s;
        transform: translateY(-150vh);
        z-index: 1111;
    }
    header .responsive-nav{
        transform: none;
    }
    header{
        border-radius: 0;
        height: 11vh;
        box-shadow: none;
    }

    nav .nav-close-btn{
        position: absolute;
        top: 2rem;
        right: 2rem;
    }
    nav .nav-link{
        font-size: 1.5rem;
    }
}





// .navbar-nav{
//     height: 13vh;
//     width: 100%;
//     background-color: white;
//     position: fixed;
//     z-index: 1;
//     border-radius: 0 0 30px 30px;
//     display: grid;
//     grid-template-columns: 90%;
//     align-items: center;
//     justify-content: center;
//     box-shadow: 0px 2px 5px rgba($color: #dcdcdc, $alpha: 1.0);
//     .navbar-main-inside{
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         column-gap: 1rem;
//         .nav-logo{
//             height: 12.5vh;
//         }
//         .icon-flex{
//             display: flex;
//             flex-direction: row;
//             justify-content: flex-end;
//             align-items: center;
//             column-gap: 3rem;
//             width: 100%;
//             margin-left: auto;
//             .link{
//                 color: rgba(0, 0, 0, 0.52);
//             }
//             .selected-link{
//                 transition: 0.2s;
//                 color: black;
//             }
//             .main-choice{
//                 border: 2px solid #787878;
//                 color: #787878;
//                 padding: 0.3rem;
//                 border-radius: 6px;
//                 text-align: center;
//             }
//         }
//         select{
//             background-color: #F5F5F5;
//             color: #8D8D8D;
//             border: none;
//             padding: 10px; 
//             border-radius: 6px;
    
//         }
//     }
    
    
// }


// .nav-space{
//     height: 13vh;
// }