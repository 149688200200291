.hotel-status-popup-main{
    top: 0;
    left: 0;
    min-height: 100vh;
    min-width: 100%;
    z-index: 11;
    position: fixed;
    background-color: rgba($color: #000000, $alpha: 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    .hotel-status-popup{
        background-color: white;
        min-height: 20vh;
        min-width: 25%;
        position: relative;
        padding: 1rem 2rem;
        box-shadow: 0px 2px 15px rgba($color: #3a3a3a, $alpha: 1.0);
        border-radius: 10px 10px 10px 10px;
        .hotel-status-popup-close-btn{
            font-size: 2rem;
            position: absolute;
            top: 2%;
            right: 2%;
            cursor: pointer;
        }
        .hotel-status-popup-sub{
            display: grid;
            align-items: center;
            justify-content: center;
            .hotel-status-popup-text{
                font-size: 1.2rem;
                padding: 2rem 0;
            }
            .hotel-status-popup-btn{
                display: flex;
                justify-content: center;
            }
        }
    }
}