.menuchange-main{
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; 
    }
    .menu-label{
        font-size: 0.7rem;
        margin-bottom: 0.5rem;
    }
    textarea:focus, input:focus{
        outline: none;
    }
    textarea{
        resize: none;
        margin-bottom: 1.5rem;
        padding: 0.5rem;
        // background: #E1E1E1;
        border-radius: 7px;
        border: none;
    }
    input{
        // margin-bottom: 1.5rem;
        // padding: 0.5rem;
        // background: #E1E1E1;
        // border-radius: 7px;
        // border: none;
    }
    .item-name{
        width: 20rem;
    }
    .item-price{
        width: 3rem;
    }
    button{
        cursor: pointer;
    }
}
.add-item{
    margin-bottom: 1rem;
    .add-item-main{
        display: grid;
        column-gap: 1rem;
        row-gap: 1rem;
    }
}
.edit-item{
    margin-bottom: 1rem;
    .edit-item-main{
        display: grid;
        column-gap: 1rem;
        row-gap: 1rem;
    }
}
.menuchange-btn{
    background: #485861;
    border-radius: 5px;
    border: none;
    padding: 0.5rem 2rem;
    color: white;
}

@media only screen and (max-width: 500px) {
    .settings-heading{
        font-size: 0.8rem;
    }
}