.settings-time{
    display: flex;
    column-gap: 2rem;
    margin-bottom: 3rem;
}
.settings-openon-heading{
    font-size: 1.2rem;
    font-weight: 550;
    margin-bottom: 1rem;
}
.settings-time-btn{
    margin-top: 3rem;
}
.settings-days{
    display: flex;
    column-gap: 1rem;
}

@media only screen and (max-width: 500px) {
    .settings-time{
        display: grid;
        column-gap: 0;
        row-gap: 1rem;
        margin-bottom: 2rem;
    }
    .settings-days{
        display: grid;
        column-gap: 0;
        row-gap: 0.2rem;
    }
    .settings-openon-heading{
        font-size: 1rem;
        margin-top: 1rem;
    }
    .settings-time-btn{
        margin-top: 1rem;
    }
    .css-ahj2mt-MuiTypography-root{
        font-size: 0.8rem !important;
    }
}