.inventory-main{
    display: grid;
    grid-template-columns: 80%;
    justify-content: center;
    padding-top: 1.5rem;
    padding-bottom: 4rem;
    .inventory-sub{
        display: grid;
        grid-template-columns: 1fr 6fr;
        column-gap: 3rem;
        // height: 100px;
        .inventory-category{

            .inventory-category-heading{
                font-family: 'SF UI Text';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                color: rgba(0, 0, 0, 0.6);
            }
            .inventory-category-hr-main{

                .inventory-category-hr{
                    width: 25%;
                    border: none;
                    border-top: 3px solid #D9D9D9;
                }
            }
            .inventory-category-sub{
                display: flex;
                justify-content: space-between;
                padding: 0.4rem 0;
            }
            
        }
        .out-of-stock-btn-main{
            display: flex;
            justify-content: flex-end;
            
            .out-of-stock-btn-inactive{
                cursor: pointer;
                border-radius: 7.36843px;
                padding: 0.5rem 1.5rem;
                border: none;
                color: rgba(110, 110, 110, 0.57);
                background: rgba(201, 201, 201, 0.63);
                font-size: 1rem;
            }
            .out-of-stock-btn-active{
                cursor: pointer;
                border-radius: 7.36843px;
                padding: 0.5rem 1.5rem;
                border: none;
                color: white;
                background: #FFB800;
                font-size: 1rem;
            }
        }
        .inventory-items-main{
            .category-items-heading{
                padding: 0.8rem 0;
                font-weight: 600;
                font-size: 1.2rem;
                line-height: 21px;
                color: #042E60;
            }
            
        }
    }
}

@media only screen and (max-width: 600px) {
    .inventory-category{
        display: none;
    }
    .inventory-main{
        .inventory-sub{
            display: grid;
            grid-template-columns: 100%;
            justify-content: center;
            
        }
    }
    

}