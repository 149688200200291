.edit-profile-popup-main{
    top: 0;
    left: 0;
    min-height: 100vh;
    min-width: 100%;
    z-index: 11;
    position: fixed;
    background-color: rgba($color: #000000, $alpha: 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    .edit-profile-popup{
        background-color: white;
        min-height: 20vh;
        min-width: 45%;
        position: relative;
        padding: 2rem;
        box-shadow: 0px 2px 15px rgba($color: #3a3a3a, $alpha: 1.0);
        border-radius: 10px 10px 10px 10px;
        .edit-profile-popup-sub{
            display: grid;
            row-gap: 1rem;
            .edit-pro-heading{
                margin-bottom: 0;
            }
        }
        .edit-profile-popup-close-btn{
            font-size: 2rem;
            position: absolute;
            top: 2%;
            right: 2%;
            cursor: pointer;
        }
    }
}