.inventory-items{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    align-items: center;
    .inventory-food-btn{
        cursor: pointer;
        &:hover{
            color: brown;
        }
    }
    .fa-circle-dot{
        margin-right: 0.6rem;
        font-size: 0.8rem;
        align-self: center;
    }
}

@media only screen and (max-width: 800px) {
    .inventory-items{
        display: grid;
        grid-template-columns: 2fr 1fr;
        align-items: center;
    }
    .inventory-toggle-main{
        display: grid;
        justify-content: center;
    }
}