
.main-login{
    @import url(https://fonts.googleapis.com/css?family=Roboto:300);
    height: 100vh;
    background: #3B2E5A;
    background: linear-gradient(90deg, rgb(112, 90, 152) 0%, #3B2E5A 70%);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;   
    font-family: "Roboto", sans-serif;
    .login-page {
      height: 100vh;
      display: grid;
      grid-template-columns: 25%;
      justify-content: center;
      align-items: center;
        // width: 400px;
        // padding: 8% 0 0;
        margin: auto;
      }
      .form {
        position: relative;
        z-index: 1;
        background: #FFFFFF;
        max-width: 360px;
        margin: 0 auto 100px;
        padding: 45px;
        text-align: center;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
        .errmsg{
          // color: red;
          // margin-bottom: 0;
          background-color: lightpink;
          color: firebrick;
          font-weight: bold;
          padding: 0.5rem;
          margin-bottom: 0.5rem;
        }
        .offscreen{
          position: absolute;
          left: -9999px;
        }
      }
      .h1{
          color: #042E60;
          font-weight: 200;
      }
      .form input {
        font-family: "Roboto", sans-serif;
        outline: 0;
        background: #f2f2f2;
        width: 100%;
        border: 0;
        margin: 0 0 15px;
        padding: 15px;
        box-sizing: border-box;
        font-size: 14px;
      }
      .form button {
        font-family: "Roboto", sans-serif;
        text-transform: uppercase;
        outline: 0;
        background: #3B2E5A;
        width: 100%;
        border: 0;
        padding: 15px;
        color: #FFFFFF;
        font-size: 14px;
        -webkit-transition: all 0.3 ease;
        transition: all 0.3 ease;
        cursor: pointer;
      }
      .form button:hover,.form button:active,.form button:focus {
        background: black;
      }
      .form .message {
        margin: 15px 0 0;
        color: #b3b3b3;
        font-size: 12px;
      }
      .form .message a {
        color: #042E60;
        text-decoration: none;
      }
      .form .register-form {
        display: none;
      }
      .container {
        position: relative;
        z-index: 1;
        max-width: 300px;
        margin: 0 auto;
      }
      .container:before, .container:after {
        content: "";
        display: block;
        clear: both;
      }
      .container .info {
        margin: 50px auto;
        text-align: center;
      }
      .container .info h1 {
        margin: 0 0 15px;
        padding: 0;
        font-size: 36px;
        font-weight: 300;
        color: #1a1a1a;
      }
      .container .info span {
        color: #4d4d4d;
        font-size: 12px;
      }
      .container .info span a {
        color: #000000;
        text-decoration: none;
      }
      .container .info span .fa {
        color: #EF3B3A;
      }
      
}

@media only screen and (max-width: 1300px){
  .main-login{
    .login-page{
      grid-template-columns: 32%;
    }
  }
}

@media only screen and (max-width: 1024px){
  .main-login{
    .login-page{
      grid-template-columns: 40%;
    }
  }
}
@media only screen and (max-width: 750px){
  .main-login{
    .login-page{
      grid-template-columns: 50%;
    }
  }
}
@media only screen and (max-width: 600px){
  .main-login{
    .login-page{
      grid-template-columns: 65%;
    }
  }
}
@media only screen and (max-width: 525px){
  .main-login{
    .login-page{
      grid-template-columns: 90%;
    }
  }
}