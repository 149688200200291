.settings-bottom-main{
    display: grid;
    grid-template-columns: 80%;
    justify-content: center;
    padding: 1.5rem 0;
    .settings-heading{
        color: #042E60;
        margin-bottom: 0.5rem;
        cursor: pointer;
        .fa-solid{
            margin-right: 0.5rem;
        }
    }
    .settings-bottom{
        display: grid;
        grid-template-columns: 1fr 1rem 6fr;
        column-gap: 1rem;
        .settings-vr{
            border-left: solid 1px rgba(0, 0, 0, 0.2);;
        }
        .settings-left{
            color: rgba(0, 0, 0, 0.4);
            .settings-left-hr{
                border: none;
                border-top: 3px solid #D9D9D9;
                width: 25%;
            }
            .settings-left-sub{
                display: grid;
                row-gap: 1rem;
                margin-top: 1rem;
                div{
                    cursor: pointer;
                }
                .selected-category{
                    color: #000000;
                    transition: 0.2s;
                }
            }
        }
    }
}
@media only screen and (max-width: 500px) {
    .settings-bottom-main{
        font-size: 0.8rem;
        .settings-bottom{
            display: grid;
            grid-template-columns: 1fr 10px 6fr;
            column-gap: 0.5rem;
        }
    }
}