.profile{
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; 
    }
    // .menu-label{
    //     font-size: 0.7rem;
    //     margin-bottom: 0.5rem;
    // }
    // input:focus{
    //     outline: none;
    // }
    // input{
    //     margin-bottom: 1.5rem;
    //     padding: 0.5rem;
    //     background: #E1E1E1;
    //     border-radius: 7px;
    //     border: none;
    //     width: 20rem;
    // }
    // button{
    //     cursor: pointer;
    // }
    .profile-main{
        // display: grid;

        margin-bottom: 2rem;
        row-gap: 1rem;
        .profile-sub{
            padding-bottom: 2rem;
            display: grid;
            grid-template-columns: 1fr 5fr;
            column-gap: 1rem;
            row-gap: 1rem;
            font-size: 1rem;
            align-items: center;

        }
    }
}
.profile-btn{
    background: #485861;
    border-radius: 5px;
    border: none;
    padding: 0.5rem 2rem;
    color: white;
}
@media only screen and (max-width: 500px) {
    .profile{
        .profile-main{
            .profile-sub{
                column-gap: 0.5rem;
                font-size: 0.85rem;
                display: grid;
                grid-template-columns: 1fr 6fr;
            }
        }
    }
    
}