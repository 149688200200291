.hotel-status-main{
    display: grid;
    margin-top: 2rem;
    grid-template-columns: 80%;
    justify-content: center;
    .hotel-status-sub{
        background: #485861;
        border-radius: 11px;
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem;
        h1{
            font-weight: 500;
        }
        div:nth-child(2){
            display: flex;
            column-gap: 0.5rem;
            .hotel-working-status{
                border-radius: 7.36843px;
                padding: 0.4rem 1.5rem;
            }
            .hotel-open{
                background-color: #FFB800;
            }
            .hotel-closed{
                background-color: red;
            }
            button{
                border: none;
                background-color: rgba($color: #000000, $alpha: 0);
                color: white;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}
@media only screen and (max-width: 500px) {
    .hotel-status-main{
        .hotel-status-sub {
            display: grid;
            grid-template-columns: 100%;
            padding: 1rem;
            .hotel-status-name{
                margin: 0;
                font-size: 1.5rem;
            }
            .hotel-status-location{
                margin-top: 0.3rem;
                font-size: 0.8rem;
                margin-bottom: 1rem;
            }
            .hotel-status-change{
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}