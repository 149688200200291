.expenses-bottom-main{
    display: grid;
    grid-template-columns: 80%;
    justify-content: center;
    padding: 1.5rem 0;
    .expenses-btn-div{
        display: flex;
        justify-content: flex-end;
        padding-bottom: 1.5rem;
        column-gap: 1rem;
        .expenses-btn{
            padding: 0.4rem 1rem;   
            background: #FFB800;
            border-radius: 7.37255px;
            border: none;
            color: white;
            font-weight: 500;
            font-size: 0.7rem;
        }
    }
}