.orders-main{
    display: grid;
    overflow-y: scroll;
    grid-template-columns: 80%;
    justify-content: center;
    margin-top: 1rem;
    .order-category{
        display: flex;
        justify-content: flex-end;
    }
    .order-category-bottom{
        font-size: 0.8rem;
        border: 1px solid #E4D8D8;
        border-radius: 8px;
        margin-top: 1rem;
        .orders-heading{
            display: grid;
            grid-template-columns: 3fr 2fr 2fr 2fr 2fr 1fr;
            text-align: center;
            padding: 1rem;
            font-weight: 600;
        }
        .view-invoice{
            color: navy;
            text-align: center;
            &:hover{
                text-decoration: underline;
            }
        }
        .mark-as-done-btn{
            padding: 0.3rem 0.5rem;
            border: none;
            background-color: green;
            color: white;
            border-radius: 4px;
        }
        hr{
            border: 1px solid rgba(0, 0, 0, 0.10);  
        }
    }
}