.expenses-order-main{
    .expenses-orders{
        display: grid;
        grid-template-columns: repeat(3,3fr) repeat(3,2fr);
        text-align: center;
        column-gap: 1rem;
        padding: 1rem 0;
        font-size: 0.9rem;
    }
    .expenses-heading{
        font-weight: 550;
        border: 1px solid #E4D8D8;
        border-radius: 8px 8px 0 0;
    }
    .expenses-sub{
        border: 1px solid #E4D8D8;
        border-radius: 0 0 8px 8px;
        border-top: none;
    }
    .expenses-bill{
        color: #6688FF;
    }
}