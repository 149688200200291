.inventory-popup-main{
    top: 0;
    left: 0;
    min-height: 100vh;
    min-width: 100%;
    z-index: 11;
    position: fixed;
    background-color: rgba($color: #000000, $alpha: 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    .inventory-popup{
        background-color: white;
        min-height: 20vh;
        width: 40%;
        position: relative;
        padding: 2rem;
        box-shadow: 0px 2px 15px rgba($color: #3a3a3a, $alpha: 1.0);
        border-radius: 10px 10px 10px 10px;
        display: grid;
        
        text-align: center;
        justify-content: center;
        // column-gap: 1rem;
        font-size: 1.3rem;
        // row-gap: 1rem;
        .inventory-popup-close-btn{
            font-size: 2rem;
            position: absolute;
            top: 2%;
            right: 2%;
            cursor: pointer;
        }
        .food-popup-img-div{
            // grid-template-columns: repeat(2, 1fr);
            // width: 50%;

            display: flex;
            justify-content: space-around;
            .food-popup-img{
                object-fit: contain;
                width: 50%;
            }
        }
        .inventory-popup-food-name{
            font-size: 1.5rem;
        }
        .inventory-popup-food-details{
            font-size: 0.9rem;
        }
    }
}