.revenue-card-main{
    background: #E6E6E6;
    border-radius: 19.3188px;
    display: flex;
    column-gap: 1rem;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
        .revenue-total{
            font-size: 2.5rem;
            font-weight: 600;
            padding-top: 1rem;
        }
        .revenue-card-order{
            font-size: 1.7rem;
            font-weight: 600;
            padding-top: 1rem;
        }
}

@media only screen and (max-width: 550px) {
    .revenue-card-main{
        font-size: 0.8rem;
        .revenue-total{
            font-size: 1.5rem;
        }
        .revenue-card-order{
            font-size: 1.5rem;
        }
    }
}