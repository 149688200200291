@font-face {
  font-family: Imported;
  src: url("./fonts/SFUIText-Regular.ttf");
  // src: url('./fonts/SFUIText-Bold.ttf');
  // src: url('./fonts/SFUIText-Heavy.ttf');
  // src: url('./fonts/SFUIText-Light.ttf');
  // src: url('./fonts/SFUIText-Medium.ttf');
  // src: url('./fonts/SFUIText-Semibold.ttf');
}

body {
  font-family: Imported;
  margin: 0;
  outline: none;
}
a {
  text-decoration: none;
}

.request-popup-main {
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100%;
  z-index: 11;
  position: fixed;
  background-color: rgba($color: #000000, $alpha: 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  .request-popup {
    background-color: white;
    min-height: 20vh;
    min-width: 20%;
    position: relative;
    padding: 1rem 2rem;
    box-shadow: 0px 2px 15px rgba($color: #3a3a3a, $alpha: 1);
    border-radius: 10px 10px 10px 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
    justify-content: center;
    column-gap: 1rem;
    font-size: 1.3rem;
    row-gap: 1rem;
    .popup-text-left {
      text-align: left;
    }
  }
}
