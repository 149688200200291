.noty-main{
    display: grid;
    grid-template-columns: 80%;
    justify-content: center;
    padding-top: 1.5rem;
    padding-bottom: 4rem;
    .noty-sub{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        justify-content: center;
        row-gap: 2rem;
        column-gap: 2rem;
        .noty{
            border: 1px solid rgb(187, 187, 187);
            border-radius: 10px;
            padding: 1rem;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            row-gap: 0.5rem;
            -webkit-box-shadow: 5px 7px 13px -7px rgba(0,0,0,0.68);
            -moz-box-shadow: 5px 7px 13px -7px rgba(0,0,0,0.68);
            box-shadow: 5px 7px 13px -7px rgba(0,0,0,0.68);
            .noty-btn{
                padding: 1rem 0;
            }
        }
    }
}
@media only screen and (max-width: 1250px) {
    .noty-main{
        
        .noty-sub{
            grid-template-columns: repeat(3,1fr);
        }
    }
}
@media only screen and (max-width: 950px) {
    .noty-main{
        
        .noty-sub{
            grid-template-columns: repeat(2,1fr);
        }
    }
}
@media only screen and (max-width: 650px) {
    .noty-main{
        
        .noty-sub{
            grid-template-columns: repeat(1,1fr);
        }
    }
}